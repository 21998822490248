import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {StyledCheckbox} from '../styles/global';

export default function LiikenneAiheFilter(props) {
    const initialValues = {
        paikallisliikenne: false,
        seutuliikenne: false,
        kaukoliikenne: false,
        yhdistelma: false,
    };
    const [state, setState] = React.useState(props.value && props.value.length != 0 ? props.value : initialValues);

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        let handleValue = value;
        if(event.target.type === 'checkbox') {
            handleValue = event.target.checked;
        }
        setState({ ...state, [name]: handleValue });

    };

    React.useEffect(()=>{
        props.onChange(state);
    }, [state])
    return <React.Fragment>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<StyledCheckbox checked={state.vastaanotto} color="primary" onChange={handleChange} name="paikallisliikenne" />}
            label={<Typography variant="body2">Paikallisliikenne</Typography>}
          />
          <FormControlLabel
            control={<StyledCheckbox checked={state.lahetys} color="primary" onChange={handleChange} name="seutuliikenne" />}
            label={<Typography variant="body2">Seutuliikenne</Typography>}
          />
          <FormControlLabel
            control={<StyledCheckbox checked={state.asiakaspalvelu} color="primary" onChange={handleChange} name="kaukoliikenne" />}
            label={<Typography variant="body2">Kaukoliikenne</Typography>}
          />
            <FormControlLabel
            control={<StyledCheckbox checked={state.tuotteet} color="primary" onChange={handleChange} name="yhdistelma" />}
            label={<Typography variant="body2">Yhdistelmä</Typography>}
          />
        </FormGroup>
      </FormControl>
    </React.Fragment>
}