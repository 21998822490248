import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StarIcon from '@material-ui/icons/Star';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {useStyles, StyledCheckbox} from '../styles/global';

const StarContainer = styled.div`
    margin-top: 0px;
`;
export default function ArvosteluFilter(props) {
    const classes = useStyles();
    const initialValues = {
        star1: false,
        star2: false,
        star3: false,
        star4: false,
        star5: false,
        wdba_yes: false,
        wdba_no: false
    };
    const [state, setState] = React.useState(props.value && props.value.length != 0 ? props.value : initialValues);

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        let handleValue = value;
        if(event.target.type === 'checkbox') {
            handleValue = event.target.checked;
        }
        setState({ ...state, [name]: handleValue });

    };
    
    React.useEffect(()=>{
        props.onChange(state);
    }, [state])

    return <Grid container spacing={0} justify={"center"}>
        <Grid item sm={3} lg={6} xs={12}>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<StyledCheckbox checked={state.star5} color="primary" onChange={handleChange} name="star5" />}      
            label={
                <StarContainer>
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                </StarContainer>
            }
          />
          <FormControlLabel
            control={<StyledCheckbox checked={state.star4} color="primary" onChange={handleChange} name="star4" />}
            label={
                <StarContainer>
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                </StarContainer>
            }
          />
          <FormControlLabel
            control={<StyledCheckbox checked={state.star3} color="primary" onChange={handleChange} name="star3" />}
            label={
                <StarContainer>
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                </StarContainer>
            }
          />
            <FormControlLabel
            control={<StyledCheckbox checked={state.star2} color="primary" onChange={handleChange} name="star2" />}
            label={
                <StarContainer>
                <StarIcon className={classes.yellow} />
                <StarIcon className={classes.yellow} />
                </StarContainer>
            }
          />
            <FormControlLabel
            control={<StyledCheckbox checked={state.star1} color="primary" onChange={handleChange} name="star1" />}
            label={<StarContainer><StarIcon className={classes.yellow} /></StarContainer>}
          />
        </FormGroup>
      </FormControl>
      </Grid>
      <Grid item sm={3} lg={6} xs={12}>
        <FormControl component="fieldset">
            <FormGroup>
            <FormControlLabel
                control={<StyledCheckbox checked={state.wdba_yes} color="primary" onChange={handleChange} name="wdba_yes" />}
                label={<Typography variant="body2">Jatkaa asiakkaana</Typography>}
            />
            <FormControlLabel
                control={<StyledCheckbox checked={state.wdba_no} color="primary" onChange={handleChange} name="wdba_no" />}
                label={<Typography variant="body2">Ei jatka asiakkaana</Typography>}
            />
            </FormGroup>
        </FormControl>
      </Grid>
      </Grid>
}