import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box'; 
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TilaFilter from './filter/tilafilter';
import AsiakkuusFilter from './filter/asiakkuusfilter';
import ArvosteluFilter from './filter/arvostelufilter';
import AiheFilter from './filter/aihefilter';
import LiikenneAiheFilter from './filter/liikenneaihefilter';
import TextField from "@material-ui/core/TextField";
import { withStyles} from '@material-ui/core/styles';

const StyledTextField = styled(TextField)`
    padding-bottom:5px;
`;

const FilterContainer = styled(Box)`

`;

const FilterCard = styled(Card)`
    margin-top: 9px;
    padding: 10px 10px;
    text-align:left;
`;

const FilterButton = styled(Button)`
    margin:1px;
    max-width: 100px;
`;

const FilterPanelButton = styled(Button)`
    margin: 10px auto;
    display:block;
`;

const StyledResetButton = withStyles({
    root: {
        backgroundColor: 'transparent',
    },
})((props) => <Button size="small" color="default" style={{marginTop:'5px'}} {...props} />);

export default function CaseFilter(props) {
    const initialValues = {
        searchText: '',
        aihe: [],
        liikenneaihe: [],
        arvostelu: [],
        tila: [],
        asiakkuus: [],
        slug: props.brand.slug
    };

    const onResetFilters = () => {
        props.onFilter('');
        setState(initialValues);
        setFiltered(false);
    };

    const [filtered, setFiltered] = React.useState(false);
    const [state, setState] = React.useState(initialValues);
    const onSearchClick = (event) => {
        //console.log("SEARCH: "+state);
        let filterStr = [];
        Object.entries(state).map(([key,value],i) => {
            let filterProp = key;
            let entries = [];
            if(filterProp === 'searchText') {
                entries.push(encodeURIComponent(value));
            }
            else {
                Object.entries(value).map((entry) => {
                    if(entry[1] === true)
                        entries.push(entry[0]);
                }) 
            }
            filterStr.push(filterProp+"="+entries.join(','));
        });

        props.onFilter("&"+filterStr.join('&'));
        setFiltered(true);
    }

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        let handleValue = value;
        if(event.target.type === 'checkbox') {
            handleValue = event.target.checked;
        }
        setState({ ...state, [name]: handleValue });
    };

    const handleAiheChange = (values) => {
        //console.log(values);
        setState({ ...state, ['aihe']: values });
    }

    const handleLiikenneAiheChange = (values) => {
        //console.log(values);
        setState({ ...state, ['liikenneaihe']: values });
    }

    const handleArvosteluChange = (values) => {
        //console.log(values);
        setState({ ...state, ['arvostelu']: values });
    }

    const handleTilaChange = (values) => {
        //console.log(values);
        setState({ ...state, ['tila']: values });
    }

    const handleAsiakkuusChange = (values) => {
        //console.log(values);
        setState({ ...state, ['asiakkuus']: values });
    }
    const getFilterContent = (filter) => {
        switch (filter) {
            case 1:
                return <FilterCard>
                    <AsiakkuusFilter value={state.asiakkuus} onChange={handleAsiakkuusChange} />
                    <FilterPanelButton color="primary" variant="contained" onClick={onSearchClick}>Suodata</FilterPanelButton>
                    </FilterCard>
            case 2:
                return <FilterCard>
                    <TilaFilter value={state.tila} onChange={handleTilaChange} />
                    <FilterPanelButton color="primary" variant="contained" onClick={onSearchClick}>Suodata</FilterPanelButton>
                </FilterCard>
            case 3:
                return <FilterCard>
                    <ArvosteluFilter value={state.arvostelu} onChange={handleArvosteluChange} />
                    <FilterPanelButton color="primary" variant="contained" onClick={onSearchClick}>Suodata</FilterPanelButton>
                    </FilterCard>
            case 4:
                return <FilterCard>
                    <AiheFilter value={state.aihe} onChange={handleAiheChange} />
                    <FilterPanelButton color="primary" variant="contained" onClick={onSearchClick}>Suodata</FilterPanelButton>
                </FilterCard>
            case 5:
                return <FilterCard>
                    <LiikenneAiheFilter value={state.liikenneaihe} onChange={handleLiikenneAiheChange} />
                    <FilterPanelButton color="primary" variant="contained" onClick={onSearchClick}>Suodata</FilterPanelButton>
                </FilterCard>
            default:
                return ''
        }
    }

    const [openFilter, setOpenFilter] = React.useState(3);

    const hasSelections = (entry) => {
        let result = false;
        const entryArray = Object.entries(entry);
        for(let i=0;i<entryArray.length;i++){
            if(entryArray[i][1] === true) {
                result = true;
                break;
            }
        };
        return result;
    }
    //console.log(props);
    return (
        <FilterContainer>
            {state.slug === 'matkahuolto-matkustajapalvelut' ?
            <>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 3 ? 3 : 3)}} endIcon={openFilter === 3 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.arvostelu) ? "primary" : "secondary"}>Arvostelu</FilterButton>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 5 ? 5 : 3)}} endIcon={openFilter === 5 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.liikenneaihe) ? "primary" : "secondary"}>Aihe</FilterButton>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 2 ? 2 : 3)}} endIcon={openFilter === 2 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.tila) ? "primary" : "secondary"}>Tila</FilterButton>
            </>
            :
            <>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 3 ? 3 : 3)}} endIcon={openFilter === 3 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.arvostelu) ? "primary" : "secondary"}>Arvostelu</FilterButton>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 4 ? 4 : 3)}} endIcon={openFilter === 4 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.aihe) ? "primary" : "secondary"}>Aihe</FilterButton>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 2 ? 2 : 3)}} endIcon={openFilter === 2 ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />} variant="contained" color={hasSelections(state.tila) ? "primary" : "secondary"}>Tila</FilterButton>
            <FilterButton size="small" onClick={()=>{setOpenFilter(openFilter !== 1 ? 1 : 3)}} endIcon={openFilter === 1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} variant="contained" color={hasSelections(state.asiakkuus) ? "primary" : "secondary"}>Asiakkuus</FilterButton>
            </>
            }
            {getFilterContent(openFilter)}
            {filtered ? <div><StyledResetButton onClick={onResetFilters} id={"reset_filter_btn"} >Poista suodatukset</StyledResetButton></div> : ''}
        </FilterContainer>
    );
}