import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { OrangeAvatar } from './styles/global';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import { Link } from "gatsby-theme-material-ui";
import Box from '@material-ui/core/Box';
import {device, deviceMax} from "../helpers/devicesizes";

const Story = styled(Typography)`
    &:hover {
        cursor: pointer;
    }
`;

const ListTitle = styled(Typography)`
    text-align: center;
    padding: 11px 0;
`;


const StyledLink = styled(Link)`
    &:hover{
        text-decoration:none;
    }
`;

const FlexBox = styled(Box)`

`;

const LeftBox = styled(Box)`
    margin-right: 8px;
`;
const RightBox = styled(Box)`
    
`;
export default function CaseCard(props) {
    const [expanded, setExpanded] = React.useState(props.expanded ? true : false);
    const {slug, publisheddate, author, title, story} = props.feedback;
    const nickname = author && author.nickname ? author.nickname : ' ';
    const date = new Date(publisheddate);
    const onExpandClick = (event) => {
        setExpanded(true);
    };
    const onCollapseClick = (event) => {
        setExpanded(false);
    };
    let shortStory = story;
    if(story.length > 150) {
        shortStory = story.substring(0, 150)+"...";
    }
    return (
        <>
            {props.title !== "" ? <ListTitle variant="body1">{props.title}</ListTitle> :''}
            <FlexBox display={'flex'}>
                <LeftBox><StyledLink to={"/palaute/"+slug}><OrangeAvatar alt={nickname[0]} text={nickname[0].toUpperCase()}/></StyledLink></LeftBox>
                <RightBox>
                        <StyledLink color="textPrimary" to={"/palaute/"+slug}>
                        <Typography variant="body2" style={{fontWeight:'500'}}>{nickname}</Typography>
                        <Typography variant="caption" style={{color: '#606060', display:'block', paddingBottom:'5px'}}>{date.toLocaleDateString()} {date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</Typography>
                        <Typography variant="body1" style={{fontSize: '14px', fontWeight:'500'}}>{title}</Typography>
                        </StyledLink>
                        {expanded ?
                            <Story onClick={onCollapseClick} variant="body2" style={{whiteSpace:'pre-wrap'}}>{story} <ArrowDropUpSharpIcon style={{display: 'inline-block', position: 'absolute'}} /></Story> :
                            <Story onClick={onExpandClick} variant="body2" style={{whiteSpace:'pre-wrap'}}>{shortStory} {shortStory.length < story.length ? <ArrowDropDownSharpIcon style={{display: 'inline-block', position: 'absolute'}} />: ''} </Story>
                        }
                </RightBox>
            </FlexBox>
        </>
    );
}