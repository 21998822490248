import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import Avatar from "@material-ui/core/Avatar";

export const StyledCircularProcess = styled(CircularProgress)`
    height: 20px; 
    width: 20px;
    margin: 0 10px;
`;

export const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#3C7FFC;',
        },
        '& label.Mui-focused': {
            color: '#3C7FFC;',
        },
    },
})(TextField);

export const StyledRadio = withStyles({
    root: {
      '&$checked': {
        color: '#3C7FFC;',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export const StyledCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#3C7FFC;',
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export const useStyles = makeStyles((theme) => ({
    orange: {
        color: "#FFFFFF",
        backgroundColor: "#FFB400",
    },
    blue: {
        color: "#FFFFFF",
        backgroundColor: "#0070B5",
    },
    yellow: {
        color: "#FFB400",    
    },
    grey: {
        color: "#c4c4c4",  
    },
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        adding: theme.spacing(3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const OrangeAvatar = (props) => {
    const classes = useStyles();

    return (
        <Avatar className={classes.orange} alt={props.alt}>{props.text}</Avatar>
    );
}

export const BlueAvatar = (props) => {
    const classes = useStyles();

    return (
        <Avatar className={classes.blue} alt={props.alt}>{props.text}</Avatar>
    );
}