import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card'; 
import Box from '@material-ui/core/Box';
import MatkahuoltoSVG from '../../svg-assets/matkahuolto.svg';
import StarIcon from '@material-ui/icons/Star';
import Axios from "axios";
import CaseFilter from "./casefilter";
import CaseList from "./caselist";
import Button from '@material-ui/core/Button';
import {navigate} from 'gatsby'; 
import {device, deviceMax} from "../helpers/devicesizes";
import Hidden from '@material-ui/core/Hidden';
import { Helmet } from "react-helmet";
import {useStyles, StyledCircularProcess} from './styles/global';

const StyledContainerCard = styled(Card)`

`;

const MatkahuoltoIcon = styled(MatkahuoltoSVG)`
    height: 63px;
    margin-right:25px;
    @media ${deviceMax.laptop} {
        margin-right:10px;
    }
`;


const StarsContainer = styled.div`
    margin: 6px 0;
`;

const StyledBox = styled(Box)`
    margin: 10px;
`;
const StyledBoxLarge = styled(StyledBox)`
    @media ${device.laptop} {
        width: 580px;
    }
`;
const StyledBoxSmall = styled(StyledBox)`
    @media ${device.laptop} {
        width: 366px;
    }
`;
const CaseFilterContainer = styled(Box)`
    text-align:center;
`;

const FeedBackButton = styled(Button)`
    margin: 10px auto;
`;

const ContainerBox = styled(Box)`
    padding-top:20px;
    @media ${device.laptop} {
        display: flex;
    }
    @media ${deviceMax.laptop} {
        display: block;
    }
`;

const FlexBox = styled(Box)`

`;

const ChildBox = styled(Box)`
`;


export default function BrandProfile(props) {
    const [slug, setSlug] = React.useState(props.slug);
    const [brand, setBrand] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const classes = useStyles();
    
    const getStars = (score) => {
        if(score === null)
            return null;
        let stars = [];
        for(let i=0;i<5; i++) {
            if (score > i)
                stars.push(<StarIcon className={classes.yellow} key={"star_"+i.toString()} />);
            else 
                stars.push(<StarIcon className={classes.grey} key={"star_"+i.toString()} />);
        }
        return stars;
    };
    
    const onFeedbackClick = (event) => {
        navigate("/");
    }

    const popUp = (e) => {
        navigate('https://no-no-no.typeform.com/to/PUMlLPSW');
    }
    const structuredDataSingle = (brand) => {
        let data = {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "@id":"https://nonono.fi/" + `${brand.slug}`+'-kokemuksia',
            "name": `${brand.name}`,
            "url": `${brand.url}`,
            "description": `${brand.name}` + " on suomalainen yritys",
            "image": window.location.origin + "/matkahuolto.png",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": `${brand.city}`,
                "streetAddress": `${brand.streetaddress}`,
                "email": `${brand.email}`,
                "postalCode": `${brand.zipcode}`,
                "addressCountry": "FI"
            },
            "contactPoint": {
                "contactType": "asiakaspalvelu",
                "email": `${brand.email}`,
                "telephone": "+" + `${brand.phone}`,
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "bestRating":"5",
                "worstRating":"1",
                "ratingValue": `${brand.avg_score}`,
                "reviewCount": `${brand.resolved_count}`,
                "reviewAspect": "Asiakaspalvelu"
            },
    
        };
        return JSON.stringify(data);
    };
    
    
    React.useEffect(() => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        Axios.get(props.serverUrl + "/brand/" + props.slug +"/", {headers: headers})  
        .then(function (response) {
            //console.log(response);
            if(response.data.status === 'error') {
                setError(true);
            }
            else {
                setBrand(response.data.data);
            }
        }) 
        .catch(function (error) {
            console.log(error);
            setError(true);
        });
    }, [slug]);

    if(brand === null) {
        return <StyledCircularProcess color="secondary" /> 
    }
    const defaultFilter = "&targetcompany="+props.slug; 
    const stars = getStars(Math.round(brand.avg_score));
    const structuredData = structuredDataSingle(brand);
    return (
        <>
        <Hidden mdDown>
            <ContainerBox justifyContent="center">
                <Typography variant="h1" style={{fontSize:'28px', textAlign: 'center'}}>{brand.pagetitle}</Typography>
            </ContainerBox>
        </Hidden>
        <ContainerBox justifyContent="center">
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">{structuredData}</script>
            </Helmet>
            <StyledBoxSmall>
                <StyledContainerCard>
                    <FlexBox display={"flex"} justifyContent={"center"} alignItems={"center"}  style={{paddingTop:'20px'}}>
                        <MatkahuoltoIcon />
                        <ChildBox>
                            <Typography variant="h6">{brand.name}</Typography>
                            <a href={brand.url} style={{textDecoration:'none'}}>www.matkahuolto.fi</a>
                            <StarsContainer>
                                {stars}
                            </StarsContainer>
                        </ChildBox>
                    </FlexBox>
                    <StyledBox style={{textAlign:'center'}}>
                        <div style={{textAlign:'left', width: '180px', margin:'10px auto 20px auto'}}>
                            <Typography variant="subtitle2" style={{fontWeight: '500'}}>{brand.published_count} raportoitua asiaa</Typography>
                            <Typography variant="subtitle2" style={{fontWeight: '500'}}>{brand.resolved_count} arvostelua</Typography>
                            <Typography variant="subtitle2" style={{fontWeight: '500'}}>{brand.wydba_yes_count}/{brand.resolved_count} jatkaa asiakkaana</Typography>
                        </div>
                        {false ? <FeedBackButton variant="contained" size="large" color="primary" onClick={popUp}>Raportoi ongelma</FeedBackButton>  : ''}
                    </StyledBox>
                </StyledContainerCard>
            </StyledBoxSmall>
            <Hidden lgUp>
            <StyledBox>
                    <StyledContainerCard>
                        <CaseFilterContainer>
                            <Typography variant="body1" style={{textAlign: 'center', margin:'16px 0 5px 0'}}>Suodata</Typography>
                            <CaseFilter onFilter={setFilter} brand={brand} />
                        </CaseFilterContainer> 
                    </StyledContainerCard>
            </StyledBox>
            <ContainerBox justifyContent="center">
                <Typography variant="h1" style={{fontSize:'20px', textAlign: 'center'}}>{brand.pagetitle}</Typography>
            </ContainerBox>
            </Hidden>
            <StyledBoxLarge>
                <CaseList id={"1"} serverUrl={props.serverUrl} filter={filter ? defaultFilter + "&"+filter: defaultFilter} />
            </StyledBoxLarge>
            <Hidden mdDown>
            <StyledBoxSmall>
                    <StyledContainerCard>
                        <CaseFilterContainer>
                            <Typography variant="body1" style={{textAlign: 'center', margin:'16px 0 5px 0'}}>Suodata</Typography>
                            <CaseFilter onFilter={setFilter} brand={brand} />
                        </CaseFilterContainer> 
                    </StyledContainerCard>
            </StyledBoxSmall>
            </Hidden>
        </ContainerBox>
        </>
    );
}