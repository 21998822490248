import React from 'react';
import Axios from "axios";
import CaseCard from "./casecard";
import CaseComments from "./case-comments";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {StyledCircularProcess} from './styles/global';
import {navigate} from 'gatsby'; 


const StyledCard = styled(Card)`
    margin-bottom: 10px;
    padding: 16px;
`;

const ButtonBox = styled(Box)`
    text-align:center
`;

const StyledButton = styled(Button)`
    width:170px;
`;

export default function CaseList(props) {
    const defaultQuery = props.serverUrl + "/rest/cases/?ordering=-publisheddate&page=1"+props.filter;
    const [next, setNext] = React.useState(defaultQuery);
    const [loading, setLoading] = React.useState(true);
    const [feedbacks, setFeedbacks] = React.useState([]);
    const [initialized, setInitialized] = React.useState(false);
    const onNextPage = (event) => {
        search(feedbacks, next, props.filter, window.pageYOffset);
    }

    const toPermalinkPage = (slug) => {
        navigate('/palaute/'+slug)
    }

    const search = (currentFeedbacks, query, filter, scrollPosition = 0) => {
        setLoading(true);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        Axios.get(query+filter, {headers: headers})  
        .then(function (response) {
            //console.log(response);
            if(response.data.status === 'error') {
                setFeedbacks([]);
            }
            else {
                let tmp = currentFeedbacks;
                setFeedbacks(tmp.concat(response.data.results));
                setNext(response.data.next);
            }
            setInitialized(true);
            setLoading(false);
            if(scrollPosition) {
                window.scrollTo(0, scrollPosition);
            }
        }) 
        .catch(function (error) {
            console.log(error);
        });
    };
    React.useEffect(() => {
        setFeedbacks([]);
        search([], defaultQuery, props.filter);
    }, [props.filter]);

    let f = []
    if(initialized) {
        if(feedbacks.length != 0) {
            feedbacks.map(feedback => {
                f.push(
                <StyledCard key={feedback.slug+"_styledcard"}>
                    <CaseCard key={feedback.slug+"_casecard"} feedback={feedback} serverUrl={props.serverUrl} />
                    <CaseComments key={feedback.slug+"_comments"} slug={feedback.slug} serverUrl={props.serverUrl} />
                    <div style={{textAlign:'center'}}>
                        <Button style={{margin: '25px 0', width: '126px'}} variant="contained" size="large" color="primary" onClick={()=>{toPermalinkPage(feedback.slug)}}>Kommentoi</Button>
                    </div>
                </StyledCard>
                );
            });
            if(next !== null) {
                f.push(<ButtonBox key={"buttonbox"}><StyledButton key={"btnloadmore"} variant="contained" size="large" color="primary" onClick={onNextPage} id={"load_more"}>Lataa lisää...</StyledButton></ButtonBox>)
            }       
        }
        else if(!loading) {
            f.push(<React.Fragment>
                <Typography variant="body1" style={{textAlign: 'center', margin:'16px 0 5px 0'}}>Yhtään palautetta ei löytynyt...</Typography>
            </React.Fragment>);
        }
    }
    if(loading) {
        f.push(<StyledCircularProcess color="secondary" />);
    }
    return f;
}