import React from 'react';
import Axios from "axios";
import CommentCard from './commentcard';
import {StyledCircularProcess} from './styles/global';

export default function CaseComments(props) {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(true);
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        setLoading(true);
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        Axios.get(props.serverUrl + "/comments/"+props.slug+"/?ordering=date", {headers: headers})  
        .then(function (response) {
            //console.log(response);
            
            if(response.data.status === 'error') {
                setData(null);
                setError(true);
            }
            else {
                setData(response.data.results);
                setError(false);
            }
            setLoading(false);
        }) 
        .catch(function (error) {
            console.log(error);
            setLoading(false);
            setError(true);
        });
    }, [props.slug]);

    if(loading) {
        return <StyledCircularProcess color="secondary" /> 
    }

    let commentList = []
    if(data !== null) {
        data.map(item => {
            commentList.push(<CommentCard key={"comment_"+item.id} data={item} public={true} />);
        })
    }
    return commentList;
}