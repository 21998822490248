import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {StyledCheckbox} from '../styles/global';

export default function TilaFilter(props) {
    const initialValues = {
        valmis: false,
        kesken: false,
    };
    const [state, setState] = React.useState(props.value && props.value.length != 0 ? props.value : initialValues);

    const handleChange = (event) => {
        const { target: { name, value } } = event;
        let handleValue = value;
        if(event.target.type === 'checkbox') {
            handleValue = event.target.checked;
        }
        setState({ ...state, [name]: handleValue });
    };

    React.useEffect(()=>{
        props.onChange(state);
    }, [state])
    return <React.Fragment>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={<StyledCheckbox checked={state.valmis} color="primary" onChange={handleChange} name="valmis" />}
            label={<Typography variant="body2">Palaute käsitelty</Typography>}
          />
          <FormControlLabel
            control={<StyledCheckbox checked={state.kesken} color="primary" onChange={handleChange} name="kesken" />}
            label={<Typography variant="body2">Palautteen käsittely kesken</Typography>}
          />
        </FormGroup>
      </FormControl>
    </React.Fragment>
}