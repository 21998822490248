import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import StarIcon from '@material-ui/icons/Star';
import { useStyles, OrangeAvatar, BlueAvatar} from './styles/global';
import Avatar from '@material-ui/core/Avatar';
import MHLogo from '../../static/matkahuolto.png';
import Box from '@material-ui/core/Box';
import {device, deviceMax} from "../helpers/devicesizes";

const StyledDivider = styled(Divider)`
    margin: 16px 8px;
`;

const StarsContainer = styled.div`
    margin: 6px 0 0 0;
`;

const StyledAvatar = styled(Avatar)`
    img {
        object-fit: contain;
    }
`;

const FlexBox = styled(Box)`
`;

const LeftBox = styled(Box)`
    margin-right: 12px;
`;
const RightBox = styled(Box)`
    
`;

export default function CommentCard(props) {
    const urlify = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex)
           .map(part => {
              if(part.match(urlRegex)) {
                 return <a href={part} target={"_blank"}>{part}</a>;
              }
              return part;
           });
    }
    const classes = useStyles();
    const {id, user, date, text, isreview, wydba, score} = props.data;
    const nickname = user.nickname ? user.nickname : ' ';
    const color = user.isbranduser ? classes.blue : classes.orange;
    let topic = "";
    if(!props.public) {
        topic = 'isreview' in props.data ? "Julkinen vastaus" : "Yksityinen vastaus";
        if('isclosuresuggestion' in props.data){
            topic = "Ratkaisuehdotus"
        }
    }
    else if(isreview){
        topic = "Arvostelu asian hoitamisesta";
    }

    const d = new Date(date);
    const getStars = (score) => {
        if(score === null)
            return null;
        let stars = [];
        for(let i=0;i<5; i++) {
            if (score > i)
                stars.push(<StarIcon className={classes.yellow} key={"star_"+i.toString()} />);
            else 
                stars.push(<StarIcon className={classes.grey} key={"star_"+i.toString()} />);
        }
        return stars;
    };
    const stars = getStars(score);
    return (
        <>
            <StyledDivider />
            <FlexBox display={"flex"}>
                <LeftBox>{user.isbranduser ? 
                    <StyledAvatar src={MHLogo} alt={nickname[0]} text={nickname[0].toUpperCase()} /> : 
                    <OrangeAvatar alt={nickname[0]} text={nickname[0].toUpperCase()} />
                }
                </LeftBox>
                <RightBox>
       
                        <Typography variant="body2" style={{fontWeight:'500'}}>{nickname}</Typography>
                        <Typography variant="caption" style={{color: '#606060', display:'block', paddingBottom:'5px'}}>{topic} {d.toLocaleDateString()} {d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</Typography>
                        {isreview ? 
                        <React.Fragment>
                                <StarsContainer>
                                {stars}
                                <Typography style={{display:'inline-block', margin: '0 10px', position: 'relative', bottom: '6px'}} variant="caption">Tyytyväisyys Matkahuoltoon: <strong>{score}/5</strong></Typography>
                                </StarsContainer>
                                <Typography variant="caption" style={{paddingBottom: '4px', display:'inline-block','verticalAlign': 'middle'}}>Asioinko jatkossa Matkahuollon kanssa? </Typography>{wydba ? <ThumbUpIcon style={{color:'#3C7FFC', margin: '0 0 0 10px'}} />: <ThumbDownIcon style={{margin: '5px 0 0 10px', color:'#CC3300'}} />}
                           
                        </React.Fragment>
                        : ''
                        }
                        <Typography variant="body2" style={{whiteSpace:'pre-wrap'}}>{urlify(text)}</Typography>

                </RightBox>
            </FlexBox>
            
        </>
    );
}